<template>
    <div class="plugCode">
        <div class="group-table padd20">
            <div class="duoshao" style="justify-content: center; width:100%;">
                <div class="jihmsc">
                    <div class="title">店铺id：</div>
                    <el-input v-model="valueData.shop_id" placeholder="请输入内容" style="margin-left:20px"></el-input>
                </div>
                <div class="jihmsc">
                    <div class="title">店铺名称：</div>
                    <el-input v-model="valueData.shop_name" placeholder="请输入内容" style="margin-left:20px"></el-input>
                </div>
                <div class="jihmsc">
                    <div class="title">电话：</div>
                    <el-input v-model="valueData.phone" placeholder="请输入内容" style="margin-left:20px"></el-input>
                </div>
                
                <el-button type="primary" @click="ongetRegistrationCodeList()">搜索</el-button>
            </div>
            <div style="display:flex; align-items: center;margin-bottom:20px">
                <div class="title" style="margin-right:20px">下单插件</div>
                <el-button type="primary" @click="oncodeDialog()" style="margin-left:0;">创建店铺</el-button>
                <div class="lvtotal">
                     总条数：{{lvtotal}} 条
                </div>
            </div>
             <el-table ref="filterTable" :data="CodeList" style="width: 100%">
                <el-table-column prop="shop_id" label="店铺id"></el-table-column>
                <el-table-column prop="shop_name" label="店铺名称"></el-table-column>
                <el-table-column prop="phone" label="电话"></el-table-column>
                <el-table-column prop="created_at" label="生成时间"></el-table-column>
                <el-table-column prop="expire_date" label="到期时间"></el-table-column>
                <el-table-column prop="id" label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" @click="oncodeDialog(scope.row)">编辑</el-button>
                        <el-button type="danger" @click="ondelRegistrationCode(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
             </el-table>
        </div>
        <el-dialog  title="提示" :visible.sync="codeDialog" width="500px"  @close="onCancel" :close-on-click-modal='false'>
            <div class="dialog-inside videoDialog">
                <div class="sunbox">
                    <div class="sunbox-text">店铺名称</div>
                    <div class="sunbox-content" style="width:500px">
                        <el-input v-model="paramData.shop_name" placeholder="请输入内容" ></el-input>
                    </div>
                </div>
                <div class="sunbox">
                    <div class="sunbox-text">店铺id</div>
                    <div class="sunbox-content" style="width:500px">
                        <el-input v-model="paramData.shop_id" placeholder="请输入内容" ></el-input>
                    </div>
                </div>
                <div class="sunbox">
                    <div class="sunbox-text">电话</div>
                    <div class="sunbox-content" style="width:500px">
                        <el-input v-model="paramData.phone" placeholder="请输入内容" ></el-input>
                    </div>
                </div>
                <div class="sunbox">
                    <div class="sunbox-text">到期时间</div>
                    <div class="sunbox-content" style="width:500px">
                        <el-date-picker v-model="paramData.expire_date" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
                    </div>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="onCancel">取 消</el-button>
                <el-button type="primary" @click="oncreateRegistrationCode()">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            CodeList:[],
            lvtotal:'',
            codeDialog:false,
            num:'',
            valueData:{
                shop_name:'',
                shop_id:'',
                phone:'',
            },
            paramData:{
                shop_name:'',
                shop_id:'',
                phone:'',
                expire_date:'',
                id:'',
            }
            
        };
    },
    
    mounted() {
        this.ongetRegistrationCodeList()
    },

    methods: {
        ongetRegistrationCodeList(){
            var param = {
                shop_name:this.valueData.shop_name,
                shop_id:this.valueData.shop_id,
                phone:this.valueData.phone,
            }
            this.$service.get(this.$api.extendgetList,param, (res)=> {
                if(res.code == '200'){
                    this.CodeList = res.data.data
                    this.lvtotal = res.data.total
                }
            })
        },
        //删除
        ondelRegistrationCode(row){
            var param = {
                id:row.id
            }
            this.$service.post(this.$api.extenddelete,param, (res)=> {
                if(res.code == '200'){
                    this.$message({message: '删除成功',type: 'success'});
                    this.ongetRegistrationCodeList()
                }
            })
        },
        oncodeDialog(row){
            if(row){
                this.paramData.shop_name = row.shop_name;
                this.paramData.shop_id = row.shop_id;
                this.paramData.phone = row.phone;
                this.paramData.expire_date = row.expire_date;
                this.paramData.id = row.id;
            }
            this.codeDialog = true
        },
        //生成激活码
        oncreateRegistrationCode(){
            var param = {
                shop_name:this.paramData.shop_name,
                shop_id:this.paramData.shop_id,
                phone:this.paramData.phone,
                expire_date:this.paramData.expire_date,
            }
            this.paramData.id ? param.id = this.paramData.id : ''
            this.$service.post(this.$api.extendsave,param, (res)=> {
                if(res.code == '200'){
                    this.$message({message: '生成成功',type: 'success'});
                    this.ongetRegistrationCodeList()
                    this.onCancel()
                }
            })
        },
         //取消||关闭触发
        onCancel(){
            this.codeDialog = false;
            this.paramData.shop_name = '';
            this.paramData.shop_id = '';
            this.paramData.phone = '';
            this.paramData.expire_date = '';
            this.paramData.id = '';
        },
    },

    

    // methods: {
    // 测试用
    // onceshi(){
    // 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
    // 		console.log(123)
    // 		if(res.code == '200'){

    // 		}
    // 	})
    // },
    // }
};
</script>

<style lang="scss" scoped>
.plugCode{
    .jihmsc{
        display: flex;
        align-items: center;
        margin-left: 40px;
        .el-input{
            width: 200px;
        }
    }
}
</style>
<style lang="scss">
</style>